export function saveData(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function saveDataKey(globalKey, localKey, value) {
    const jsonData = localStorage.getItem(globalKey)

    if (jsonData) {
        const parsedData = JSON.parse(globalKey);

        parsedData[localKey] = value;

        const updatedJsonData = JSON.stringify(parsedData);
    
        localStorage.setItem(globalKey, updatedJsonData);
    }
}

export function getData(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function removeData(key) {
    localStorage.removeItem(key)
}