import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Card, Flex, Empty, Button } from 'antd'
import { getProcessingResults, getResultZipUrl } from '../../utils/api'

const ProcessingResults = ({ requestId }) => {
    const [activeTabKey, setActiveTabKey] = useState('tab1');
    const [zipButton, setZipButton] = useState(null)
    const [results, setResults] = useState([{
        "files": [
            {
                "file": "",
                "thumb": ""
            }
        ],
        "folder": ""
    }])

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const ResultCard = ({ files }) => (
        files.map(({ file, thumb }) => (
            <Flex key={file} style={{ width: '100px' }}>
                <a href={file}><img src={thumb} style={{ width: '100%' }} /></a>
            </Flex>
        ))
    )

    const [tabList, contentList] = results.reduce((acc, { folder, files }) => {
        acc[0].push({ key: folder, label: folder })
        acc[1][folder] = files.length !== 0
            ? <Flex gap='middle' wrap='wrap'> <ResultCard files={files} />  </Flex>
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

        return acc
    }, [[], {}])

    const downloadResult = (url) => {
        window.open(url, '_blank');
    }

    const checkResultZipUrl = () => {
        getResultZipUrl(requestId).then(
            ({ data }) => {
                if (data.zipped == true) {
                    setZipButton(
                        <Button
                            type="primary"
                            onClick={() => downloadResult(data.zip_link)}
                        >
                            Baixar resultado
                        </Button>
                    )
                } else {
                    setTimeout(checkResultZipUrl, 5000);
                }
            }
        );
    }

    useEffect(() => {
        setZipButton(<Button type="primary" loading>Compactando arquivos...</Button>)
        checkResultZipUrl();
        getProcessingResults(requestId).then(({ data }) => setResults(data.result))
    }, [])

    useLayoutEffect(() => {
        setActiveTabKey(results[0].folder);
    }, [results])

    return (
        <Card style={{ width: '100%' }}
            bordered={false}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
            extra={zipButton}
        >
            {contentList[activeTabKey]}
        </Card >
    )
}

export default ProcessingResults