import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Upload, Card, Avatar, Flex, Input, Button, Typography, notification, message } from "antd";
import { LoadingOutlined, PlusOutlined, AntDesignOutlined } from '@ant-design/icons';
import { createInputUser, getInputUser, updateInputUser } from "../../utils/api";
import DynamicPhone from "../../components/DynamicPhone"
import Header from "../../components/Header";
import './athlete.scss'
import { formatPhoneNumber } from "../../utils/formattingText";


const AthletePage = () => {
    const { athleteId } = useParams();
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [athleteImageUrl, setAthleteImageUrl] = useState(null);
    const { control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            name: "",
            email: "",
            cellphone: ""
        },
    })

    useEffect(() => {
        if (athleteId != undefined){
            getAthleteInformation(athleteId);
        }
    }, []);

    const getAthleteInformation = async (athleteId) => {
        try {
            const athlete = await getInputUser(athleteId);

            reset({
                name: athlete.data.name,
                email: athlete.data.email,
                cellphone: formatPhoneNumber(athlete.data.cellphone)
            });

            setAthleteImageUrl(athlete.data.image_link);
            
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível carregar as informações do atleta.')
        }
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                width: '300px',
                cursor: "pointer"
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Carregar Avatar
            </div>
        </button>
    );

    const handleAvatarChange = (info) => {
        setLoading(true);

        getBase64(info.file, (url) => {
            setLoading(false);
            setImageUrl(url);
        });
    }

    const onSubmit = async (data) => {
        const payload = { ...data }
        console.log("payload", payload)        
        payload.image = imageUrl
        payload.cellphone = payload.cellphone.replace(/\(|\)| |-/g, "")

        if (athleteId) { // Update
            try {
                const response = await updateInputUser(athleteId, payload)
                openNotification('success', 'Sucesso', 'O atleta foi atualizado com sucesso.')
                reset({
                    name: response.data.name,
                    email: response.data.email,
                    cellphone: formatPhoneNumber(response.data.cellphone)
                });
                setAthleteImageUrl(response.data.image_link);

            } catch (e) {
                if (e.response.data.error === 'Email is already in use') {
                    openNotification('error', 'Erro', 'O email informado já está em uso.')
                    return
                }
    
                openNotification('error', 'Erro', 'Ocorreu algum erro ao tentar criar o atleta. Tente novamente.')
            }

        } else { // Register
            if (payload.image == null){
                openNotification('error', 'Atenção', 'A imagem do atleta é obrigatória!')
                return;
            }
    
            try {
                const { data } = await createInputUser(payload)
                openNotification('success', 'Sucesso', 'O atleta foi cadastrado com sucesso.')
                reset()
                setImageUrl(null)
            } catch (e) {
                if (e.response.data.error === 'Email is already in use') {
                    openNotification('error', 'Erro', 'O email informado já está em uso.')
                    return
                }
    
                openNotification('error', 'Erro', 'Ocorreu algum erro ao tentar criar o atleta. Tente novamente.')
            }
        }
    }

    return (
        <Layout className="layout">
            <Header />
            <Card bordered={false} className="card">
                {contextHolder}
                <Flex align="center" justify="center">
                    <Flex vertical gap="middle" className='card-wrapper'>
                        <Flex className="title-wrapper" justify="center">
                            <Typography.Title level={2}>{athleteId ? 'Atualizar' : 'Cadastrar'} Atleta</Typography.Title>
                        </Flex>
                        <Upload
                            className='upload-button'
                            name="avatar"
                            listType="picture-circle"
                            showUploadList={false}
                            beforeUpload={() => false}
                            onChange={handleAvatarChange}
                        >
                            {imageUrl || athleteImageUrl ? (
                                <Avatar
                                    className='avatar'
                                    size='large'
                                    src={imageUrl ?? athleteImageUrl}
                                    shape='circle'
                                    icon={<AntDesignOutlined />}
                                />
                            ) : uploadButton}
                        </Upload>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex vertical gap="middle">
                                <Flex vertical gap="small">
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'O campo nome é obrigatório'}                                            
                                        }}
                                        render={({ field }) => 
                                            <Input 
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Nome"
                                                status={errors.name ? "error" : null}
                                            />}
                                    />
                                    {errors.name && <span>{errors.name.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'O campo email é obrigatório' },
                                            pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "O email informado não é um email válido." }
                                        }}
                                        render={({ field }) => 
                                            <Input
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Email"
                                                status={errors.email ? "error" : null}
                                            />}
                                    />
                                    {errors.email && <span>{errors.email.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="cellphone"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'O campo telefone é obrigatório' },
                                            pattern: { 
                                                value: /^\(\d{2}\) \d{1} \d{4}-\d{3}[0-9]$/,
                                                message: "O telefone informado não é um telefone válido." 
                                            }
                                        }}
                                        render={({ field }) => 
                                            <DynamicPhone 
                                                {...field}
                                                data-shouldfocus={athleteId != undefined}
                                                className="input ant-input"
                                                placeholder="Telefone"
                                                status={errors.cellphone ? "error" : null} />
                                            }
                                    />
                                    {errors.cellphone && <span>{errors.cellphone.message}</span>}
                                </Flex>
                                <Flex vertical className="button-wrapper">
                                    <Button type="primary" htmlType="submit" size="large" className="button" loading={isSubmitting}>{athleteId ? 'Atualizar' : 'Cadastrar'}</Button>
                                    <Button type="secondary" size="large" className="button" onClick={() => navigate('/athletes')}>Voltar</Button>
                                </Flex>
                            </Flex>
                        </form>
                    </Flex>
                </Flex>
            </Card>
        </Layout >
    )
}

export default AthletePage