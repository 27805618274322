import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Card, Flex, Input, Button, Checkbox, Typography, notification, message } from "antd";
import Header from "../../components/Header";
import './user.scss'
import { createSystemUser, getSystemUser, updateSystemUser } from "../../utils/api";
import UserType from "../../enums/userTypeEnum"

const SystemUserPage = () => {
    const { systemUserId } = useParams();
    const navigate = useNavigate()
    const [status, setStatus] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const { control, handleSubmit, reset, formState: { errors, isSubmitting }, getValues } = useForm({
        defaultValues: {
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            userType: false
        },
    })

    useEffect(() => {
        if (systemUserId != undefined){
            getSytemUserInformation(systemUserId);
        }
    }, []);

    const getSytemUserInformation = async (systemUserId) => {
        try {
            const systemUser = await getSystemUser(systemUserId);

            reset({
                name: systemUser.data.name,
                email: systemUser.data.email,
                userType: systemUser.data.user_type === UserType.UserTypeEnum.ADMIN
            });

            setIsAdmin(systemUser.data.user_type === UserType.UserTypeEnum.ADMIN)
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível carregar as informações do usuário.')
        }
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const onSubmit = async (payload) => {
        payload.userType = payload.userType ? UserType.UserTypeEnum.ADMIN : UserType.UserTypeEnum.EMPLOYEE

        if (systemUserId) { // Update
            try {
                const response = await updateSystemUser(systemUserId, payload)
                openNotification('success', 'Sucesso', 'O usuário foi atualizado com sucesso.')
                reset({
                    name: response.data.name,
                    email: response.data.email,
                    userType: response.data.user_type === UserType.UserTypeEnum.ADMIN
                });
            } catch (e) {
                if (e.response.data.error === "Password and password confirmation do not match") {
                    openNotification('error', 'Erro', 'Os campos senha e confirmação precisam ser idênticos.')
                    return
                } else if (e.response.data.error === "Email is already in use") {
                    openNotification('error', 'Erro', 'O email informado já foi cadastrado.')
                    return
                }
    
                openNotification('error', 'Erro', 'Ocorreu algum erro ao tentar atualizar o usuário. Tente novamente.')
            }

        } else { // Register
            try {
                const { data } = await createSystemUser(payload)
                openNotification('success', 'Sucesso', 'O usuário foi cadastrado com sucesso.')
                reset()
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } catch (e) {
                if (e.response.data.error === "Password and password confirmation do not match") {
                    openNotification('error', 'Erro', 'Os campos senha e confirmação precisam ser idênticos.')
                    return
                } else if (e.response.data.error === "Email is already in use") {
                    openNotification('error', 'Erro', 'O email informado já foi cadastrado.')
                    return
                }
    
                openNotification('error', 'Erro', 'Ocorreu algum erro ao tentar criar o usuário. Tente novamente.')
            }
        }
    }

    return (
        <Layout className="layout">
            <Header />
            <Card bordered={false} className="card">
                {contextHolder}
                <Flex align="center" justify="center">
                    <Flex vertical gap="middle" className="card-wrapper">
                        <Flex className="title-wrapper" justify="center">
                            <Typography.Title level={2}>{systemUserId ? 'Atualizar' : 'Cadastrar'} Usuário</Typography.Title>
                        </Flex>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex vertical gap="middle">
                                <Flex vertical gap="small">
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field }) => 
                                            <Input
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Nome"
                                                status={errors.name ? "error" : null} 
                                            />
                                        }
                                    />
                                    {errors.name && <span>{errors.email.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'O campo email é obrigatório' },
                                            pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "O email informado não é um email válido." }
                                        }}
                                        render={({ field }) => 
                                            <Input
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Email"
                                                status={errors.email ? "error" : null} 
                                            />
                                        }
                                    />
                                    {errors.email && <span>{errors.email.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: { value: (systemUserId == undefined ? true : false), message: 'O campo senha é obrigatório' },
                                        }}
                                        render={({ field }) => 
                                            <Input.Password
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Senha"
                                                status={errors.password ? "error" : null}
                                            />
                                        }
                                    />
                                    {errors.password && <span>{errors.password.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="passwordConfirmation"
                                        control={control}
                                        rules={{
                                            required: { value: (systemUserId == undefined ? true : false), message: 'O campo confirmação de senha é obrigatório' },
                                            validate: value =>
                                                value === getValues('password') || 'Os campos senha e confirmação precisam ser idênticos'
                                        }}
                                        render={({ field }) => 
                                            <Input.Password
                                                {...field}
                                                className="input ant-input"
                                                placeholder="Confirmação de senha"
                                                status={errors.passwordConfirmation ? "error" : null} 
                                            />
                                        }
                                    />
                                    {errors.passwordConfirmation && <span>{errors.passwordConfirmation?.message}</span>}
                                </Flex>
                                <Flex vertical gap="small">
                                    <Controller
                                        name="userType"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <Checkbox
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                                onBlur={onBlur}
                                                name={name}
                                                ref={ref}
                                            >
                                                Administrador
                                            </Checkbox>
                                        )}
                                    />
                                </Flex>
                            </Flex>
                            <Flex vertical className="button-wrapper">
                                <Button type="primary" htmlType="submit" size="large" className="button" loading={isSubmitting}>{systemUserId ? 'Atualizar' : 'Cadastrar'}</Button>
                                <Button type="secondary" size="large" className="button" onClick={() => navigate('/system-users')}>Voltar</Button>
                            </Flex>
                        </form>
                    </Flex>
                </Flex>
            </Card>
        </Layout>
    )
}

export default SystemUserPage