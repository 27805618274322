import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { login } from "../../utils/api";
import { Layout, Flex, Card, Button, Input, Typography, notification } from "antd";
import { saveData } from "../../utils/local-storage";
import './login.scss'

const LoginPage = () => {
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [status, setStatus] = useState()
    const { control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            email: "",
            password: ""
        },
    })

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target
        setStatus('')

        eval(`set${capitalize(name)}`)(value)
    }

    const handleSubmitForm = async () => {
        try {
            const { data } = await login(email, password)
            saveData('auth_data', data)
            openNotification('success', 'Sucesso', 'Login foi realizado com sucesso. Você será redirecionado para o sistema.')
            setTimeout(() => navigate('/'), 2000)
        } catch (e) {
            openNotification('error', 'Não Autorizado', 'Email ou senha informados são inválidos.')
            setStatus('error')
        }
    }

    return (
        <Layout className="layout">
            <Flex align="center" justify="center" className="logo-wrapper">
                <img src="/cisp1.svg" className="cisp1-login-logo" />
                <img src="/paulobarcellos.svg" className="paulo-barcellos-login-logo" />
            </Flex>
            <Card bordered={false} className="card-login">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <Flex className="title-wrapper" justify="center">
                        <Typography.Title level={2}>Login</Typography.Title>
                    </Flex>
                    <Flex vertical gap="middle">
                        <Input placeholder="E-mail" size="large" className="input" name="email" onChange={handleInputChange} status={status} />
                        <Input.Password placeholder="Senha" size="large" name="password" className="input ant-input" onChange={handleInputChange} status={status} />
                    </Flex>
                    <Flex className="button-wrapper">
                        <Button type="primary" size="large" className="button" htmlType="submit">Entrar</Button>
                    </Flex>
                </form>
            </Card>
            {contextHolder}
        </Layout >
    )
}

export default LoginPage