import React from 'react'
import { Card, Flex, Progress, Typography } from 'antd'

const ProcessingProgress = ({ percent, status, requesterEmail }) => {
    const statusMessages = {
        to_process: "Os dados serão processados em breve!",
        processing: "Os dados estão sendo processados, aguarde um momento!",
        processed: `Os dados foram processados, em breve você irá receber os resultados no email: ${requesterEmail}`,
        failed: "Ocorreu uma falha no processamento dos dados, por favor contate o time de suporte."
    }

    const StatusMessage = () => (
        <Typography.Title level={4}>{statusMessages[status]}</Typography.Title>
    )

    return (
        <Card style={{ width: '100%' }} bordered={false}>
            <Flex justify="center">
                <Progress type="circle" percent={percent} status={status === 'failed' ? 'exception' : false} />
            </Flex>
            <Flex justify="center">
                <StatusMessage />
            </Flex>
        </Card>
    )
}

export default ProcessingProgress