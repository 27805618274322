import axios from 'axios'
import { getData, removeData, saveDataKey } from './local-storage';
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use((config) => {
    const authData = getData('auth_data');
    if (authData && authData.access_token) {
        config.headers.Authorization = `Bearer ${authData.access_token}`;
    }
    return config;
},
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response.status === 401 || error.response.status === 422) {
            const currentRoute = error.config.url;
            let tokenRefreshed = false;
            
            if (currentRoute !== '/login' && currentRoute !== '/refresh-token') {
                window.location.href = '/login'

                // const authData = getData('auth_data');

                // tokenRefreshed = isAccessTokenValid(authData.access_token, authData.refresh_token)
                // console.log("tokenrefreshed", tokenRefreshed)

                // if (!tokenRefreshed){
                    // window.location.href = '/login'
                // } else {
                //     const updatedAuthData = getData('auth_data');
                //     // Refaz a solicitação
                //     const originalRequest = error.config;
                //     originalRequest.headers['Authorization'] = `Bearer ${updatedAuthData.accessToken}`;
                //     return axiosInstance(originalRequest);
                // }
            }
        }

        return Promise.reject(error)
    }
);

export default axiosInstance

const refreshAccessToken = async (refreshToken) => {
    return await axiosInstance.post(`/refresh-token`, {}, {
        headers: {
            'Authorization': `Bearer ${refreshToken}`
        }
    })
}

export const isAccessTokenValid = (accessToken) => {
    // console.log("access", accessToken)
    // console.log("refresh", refreshToken)

    if (!accessToken) {
        return false;
    }

    let decodedToken;
    try {
        decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
    } catch (error) {
        return false; // Invalid Token
    }

    if (decodedToken.exp) {
        const expirationTimestamp = decodedToken.exp * 1000;
        const currentTimestamp = new Date().getTime();

        if (currentTimestamp > expirationTimestamp) {
            // try {
            //     const response = refreshAccessToken(refreshToken);
            //     console.log(response.data)
            //     if (response.data.access_token) {
            //         saveDataKey('auth_data', 'access_token', response.data.access_token);
            //         return true;
            //     } else {
            //         console.log("else")
            //         return false;
            //     }
            // } catch (error) {
            //     console.log("Error updating access token:", error);
            //     return false;
            // }
            return false;
        }
    }

    return true;
};