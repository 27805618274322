import React from "react";
import Router from './routes'
import './reset.scss';

const App = () => {
    return (
        <Router />
    )
}

export default App