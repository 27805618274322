import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Card, Button, notification, Divider, Input, Avatar, List, Popconfirm } from "antd";
import VirtualList from 'rc-virtual-list';
import { UserAddOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getInputUserList, deleteInputUser } from "../../utils/api";
import { formatPhoneNumber } from "../../utils/formattingText";
import Header from "../../components/Header";
import './athlete.scss'

const AthletePage = () => {
    const [api, contextHolder] = notification.useNotification();
    const [listPage, setListPage] = useState(1);
    const perPage = 10
    const [emptyList, setEmptyList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const ContainerHeight = 400;
    const [data, setData] = useState([]);
    const { Search } = Input;
    const [isSearching, setIsSearching] = useState(false);

    const registerButton = <Button type="primary" href={`/athletes/register`}>Cadastrar <UserAddOutlined /></Button>

    const onSearch = async (value, _e, info) => {
        // if (info?.source == 'clear')
        //     return

        setSearchText(value);
        setData([]);
        setEmptyList(false);
        setIsSearching(true);
        
        try {
            const params = {
                search: value.trim(),
                page: 1,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(listAthletes.data.users);
            
            if (listAthletes.data.users.length < perPage){
                setEmptyList(true);
                setListPage(1);
                return;
            }

            setListPage(2);
            setIsSearching(false);
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível filtrar a lista de atletas.')
        }
    }

    const appendData = async () => {
        if (emptyList || isSearching)
            return;
        
        try {
            const params = {
                search: searchText.trim(),
                page: listPage,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(data.concat(listAthletes.data.users));
            
            if (listAthletes.data.users.length < perPage){
                setEmptyList(true);
                return;
            }

            setListPage(listPage + 1)
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível carregar a lista de atletas.')
        }
    };

    useEffect(() => {
        appendData();
    }, []);

    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            appendData();
        }
    };

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const handleRemoveAthlete = async (athleteId) => {
        try {
            const response = await deleteInputUser(athleteId)

            const params = {
                search: '',
                page: 1,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(listAthletes.data.users);
            openNotification('success', 'Sucesso', 'O atleta foi removido com sucesso.')
            
            if (listAthletes.data.users.length < perPage){
                setEmptyList(true);
                setListPage(1);
                return;
            }

            setEmptyList(false);
            setListPage(2);
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível remover o atleta.')
        }
    };

    return (
        <Layout className="layout">
            <Header />
            <Card bordered={true} title="Atletas" className="card" extra={registerButton} style={{width: '80%'}}>
                <Search
                    placeholder="Procure por nome ou e-mail"
                    allowClear
                    onSearch={onSearch}
                />
                <Divider />
                <List>
                    <VirtualList
                        data={data}
                        height={ContainerHeight}
                        itemHeight={47}
                        itemKey="id"
                        onScroll={onScroll}
                    >
                        {(item) => (
                        <List.Item 
                            key={item.email}
                            actions={[
                                <a href={`/athletes/${item.id}`}>Editar</a>,
                                <Popconfirm
                                    title="Remover Atleta"
                                    description="Você tem certeza que deseja remover este atleta?"
                                    okText="Sim"
                                    cancelText="Não"
                                    onConfirm={(e) => handleRemoveAthlete(item.id)}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                            color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Button icon={<DeleteOutlined />} danger></Button>
                                </Popconfirm>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item.image_link} />}
                                title={<a href={`/athletes/${item.id}`}>{item.name}</a>}
                                description={
                                    <div>
                                      {formatPhoneNumber(item.cellphone)} <br />
                                      {item.email}
                                    </div>
                                }
                            />
                        </List.Item>
                        )}
                    </VirtualList>
                </List>
            </Card>
            {contextHolder}
        </Layout >
    )
}

export default AthletePage