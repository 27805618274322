import React, { forwardRef, useRef, useEffect } from "react";
import MaskedInput from "antd-mask-input"

const DynamicPhone = forwardRef((props, ref) => {
    const cellphoneMask = '(00) 0 0000-0000';
    const phoneMask = '(00) 0000-0000';
    const maskedInputRef = useRef(null);

    // always memoize dynamic masks
    const mask = React.useMemo(
        () => [
            {
                mask: cellphoneMask,
                lazy: false,
            },
            {
                mask: phoneMask,
                lazy: false,
            },
        ],
        []
    );

    useEffect(() => { // Fix input bug reseting after submit without focus
        if (props['data-shouldfocus'] && maskedInputRef.current) {
            maskedInputRef.current.focus();
        }
    }, [props['data-shouldfocus']]);

    return (
        <MaskedInput
            {...props}
            ref={(inputRef) => {
                maskedInputRef.current = inputRef;
                if (ref) {
                    ref(inputRef);
                }
            }}
            mask={mask}
            maskOptions={{
                dispatch: function (appended, dynamicMasked) {
                    // const isCellPhone = dynamicMasked.unmaskedValue[2] === '9';
                    // return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    return dynamicMasked.compiledMasks[0];
                },
            }}
        />
    );
});

export default DynamicPhone