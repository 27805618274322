import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from "../components/PrivateRoute";
import LoginPage from '../pages/login';
import MainPage from '../pages/main';
import ResultsPage from '../pages/results';
import SelectAthletesPage from '../pages/main/selectAthletes.js';

import SystemUserListPage from '../pages/system-user';
import SystemUserFormPage from '../pages/system-user/register.js';

import AthleteListPage from '../pages/athlete';
import AthleteFormPage from '../pages/athlete/register.js';

export default () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<PrivateRoute requiredUserType={['admin', 'employee']}><MainPage /></PrivateRoute>} />
                <Route path='/login' Component={LoginPage} />
                
                <Route path="/system-users" element={<PrivateRoute requiredUserType={['admin']}><SystemUserListPage /></PrivateRoute>} />
                <Route path="/system-users/:systemUserId" element={<PrivateRoute requiredUserType={['admin']}><SystemUserFormPage /></PrivateRoute>} />
                <Route path="/system-users/register" element={<PrivateRoute requiredUserType={['admin']}><SystemUserFormPage /></PrivateRoute>} />
                
                <Route path='/athletes' element={<PrivateRoute requiredUserType={['admin', 'employee']}><AthleteListPage /></PrivateRoute>} />
                <Route path='/athletes/:athleteId' element={<PrivateRoute requiredUserType={['admin', 'employee']}><AthleteFormPage /></PrivateRoute>} />
                <Route path='/athletes/register' element={<PrivateRoute requiredUserType={['admin', 'employee']}><AthleteFormPage /></PrivateRoute>} />
                
                <Route path='/results/:sessionId' element={<PrivateRoute requiredUserType={['admin', 'employee']}><ResultsPage /></PrivateRoute>} />
                <Route path='/select-athletes/:sessionId' element={<PrivateRoute requiredUserType={['admin', 'employee']}><SelectAthletesPage /></PrivateRoute>} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}