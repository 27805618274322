import { useState } from 'react';

const useFileSelection = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const addFile = async (fileList) => {
    setSelectedFiles(fileList);
  };

  const removeFile = (file) => {
    setSelectedFiles((currentSelection) => {
      const newSelection = currentSelection.slice();
      const fileIndex = currentSelection.indexOf(file);

      newSelection.splice(fileIndex, 1);

      return newSelection;
    });
  };

  return [selectedFiles, addFile, removeFile, setSelectedFiles];
};

export default useFileSelection;
