import React, { useState, useEffect } from 'react'
import { Layout, Space, Card, Flex, Typography, Divider } from 'antd'
import Header from '../../components/Header'
import { useParams } from "react-router-dom";
import { getProcessingStatus } from '../../utils/api'
import ProcessingProgress from '../../components/ProcessingProgress';
import ProcessingResults from '../../components/ProcessingResults'
import { getData } from "../../utils/local-storage";

const ResultsPage = () => {
    const [percent, setPercent] = useState(0)
    const [status, setStatus] = useState()
    const [requesterEmail, setRequesterEmail] = useState()
    const { sessionId } = useParams();
    const userEmail = getData('auth_data').email

    const statusPooling = async () => {
        const { data: { task_status, progress_percentage, requester_email } } = await getProcessingStatus(sessionId)

        setStatus(task_status)
        setPercent(progress_percentage)
        setRequesterEmail(requester_email)

        if (task_status !== 'failed' && task_status !== 'processed') {
            return setTimeout(() => {
                statusPooling()
            }, 2000)
        }
    }

    useEffect(() => {
        statusPooling()
    }, [])

    return (
        <Layout style={{ width: '100vw', minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '24px', justifyContent: 'center' }}>
            <Header />
            <Card bordered={false} className="card" style={{ width: '100%' }}>
                <Flex className="title-wrapper" justify="center">
                    <Typography.Title level={2}>Resultados</Typography.Title>
                </Flex>
                {status === 'processed' ?
                    <>
                        <ProcessingResults requestId={sessionId} />
                        <Divider />
                        <Typography.Text>Link do resultado também enviado para: <Typography.Text strong>{requesterEmail}</Typography.Text></Typography.Text>
                    </>
                    :
                    <>
                        <ProcessingProgress percent={percent} status={status} requesterEmail={requesterEmail} />
                        <Divider />
                        <Typography.Text>Após o processamento, o link do resultado será enviado para: <Typography.Text strong>{requesterEmail}</Typography.Text></Typography.Text>
                    </>
                }
            </Card>
        </Layout>
    )
}

export default ResultsPage