import React, { useState } from "react"
import { Layout, Flex, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header"
import FilesCard from "../../components/FilesCard";
import useFileSelection from '../../hooks/useFileSelection';
import './main.scss'

const MainPage = () => {
    const [selectedFiles, addFile, removeFile, setSelectedFiles] = useFileSelection();
    const [isUploadDone, setIsUploadDone] = useState(true)
    const [sessionId,] = useState(new Date().getTime())
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 800

    const checkUploadingDone = (fileList) => {
        setIsUploadDone(fileList.some((val) => val.status !== "done"))
    }

    return (
        <>
            <Layout className='main-layout'>
                <Space direction="vertical" size="middle">
                    <Header />
                    <Flex vertical wrap={isMobile ? "wrap" : null} align="center" justify={'center'}>
                        <FilesCard
                            selectedFiles={selectedFiles}
                            addFile={addFile}
                            removeFile={removeFile}
                            setSelectedFiles={setSelectedFiles}
                            sessionId={sessionId}
                            checkUploadingDone={checkUploadingDone}
                            isUploadDone={isUploadDone}
                        />
                    </Flex>
                </Space>
            </Layout>
        </>
    )
}

export default MainPage