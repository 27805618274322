import React, { useEffect, useState } from 'react'
import { Flex, Card, Button, Dropdown, Space, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import './header.scss'
import { logout } from "../../utils/api"
import { removeData, getData } from "../../utils/local-storage";
import UserType from "../../enums/userTypeEnum"

const Header = () => {
    const navigate = useNavigate();
    const authData = getData('auth_data');
    const [isAdmin, setIsAdmin] = useState(authData.user_type && authData.user_type == UserType.UserTypeEnum.ADMIN)
    const isMobile = window.innerWidth < 800
    const [menuItems, setMenuItems] = useState([]);

    const handleLogout = async () => {
        try {
            const response = await logout()
            removeData('auth_data');
            navigate('/login')
        } catch (e) {
            console.log('Não foi possível fazer logout.')
            window.localtion.reload()
        }
    }

    const items = [
        {
          label: <a href="/athletes" className='large-menu-item'>Atletas</a>,
          key: '0',
        },
        {
            type: 'divider',
        },
        {
          label: <a href="/" className='large-menu-item'>Upload</a>,
          key: '2',
        },
        {
            type: 'divider',
        },
        {
          label: <a onClick={handleLogout} className='large-menu-item'>Sair</a>,
          key: '3',
        },
    ];

    useEffect(() => {
        if (isAdmin) {
            const adminItems = [
                {
                    label: <a href="/system-users" className='large-menu-item'>Usuários de Sistema</a>,
                    key: '1'
                },
                {
                    type: 'divider',
                }
            ];

            const updatedItems = [...items];
            updatedItems.splice(2, 0, ...adminItems);

            setMenuItems(updatedItems);
        } else {
            setMenuItems(items);
        }
    }, [isAdmin]);

    const menu = (
        <Menu items={menuItems} />
    );

    return (
        <Flex className="header-wrapper">
            <Card className="header-card" styles={{ borderBottomColor: '#e1e1e1' }} bordered={false}>
                <Flex className="content-wrapper" align="center" justify="space-between">
                    <Flex>
                        <img src="/cisp1.svg" className="cisp1-logo" />
                    </Flex>
                    <Flex>
                        {isMobile
                            ? <>
                                <Dropdown overlay={menu} trigger={['click']} placement="bottom">
                                    <a onClick={(e) => e.preventDefault()} >
                                        <Space>
                                            <MenuOutlined style={{ fontSize: '24px', marginTop: '10px' }} />
                                        </Space>
                                    </a>
                                </Dropdown>
                              </>
                            : <>
                                <Button type="link" className="menu-button" href="/athletes">
                                    Atletas
                                </Button>
                                {isAdmin && (
                                    <Button type="link" className="menu-button" href="/system-users">
                                        Usuários de Sistema
                                    </Button>
                                )}
                                <Button type="link" className="menu-button" href="/">
                                    Upload
                                </Button>
                                <Button type="link" className="menu-button" onClick={handleLogout}>
                                    Sair
                                </Button>
                              </>
                        }                        
                    </Flex>
                    <Flex>
                        <img src="/paulobarcellos.svg" className="paulo-barcellos-logo" />
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    )
}

export default Header