export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';

    // Remove qualquer caractere que não seja dígito
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Verifica se o número tem o tamanho esperado
    if (cleaned.length !== 11) {
        return phoneNumber;
    }

    // Formata o número de telefone
    // const country = cleaned.slice(0, 2);
    const area = cleaned.slice(0, 2);
    const firstPart = cleaned.slice(2, 3);
    const secondPart = cleaned.slice(3, 7);
    const thirdPart = cleaned.slice(7, 11);

    return `(${area}) ${firstPart} ${secondPart}-${thirdPart}`;
};